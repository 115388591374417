import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';
import { withNonPureBreakpoint } from 'core/components/breakpoint';
import { withRouter } from 'core/libs/router';

import {
  denormalizeData,
  filterRequiredParams,
} from 'core/utils/api';
import modelPropTypes, { topicAttributes, tagAttributes } from 'core/utils/prop-types/model';
import { menuPropTypes } from 'core/utils/prop-types/relationships';

import CardMain from 'site/cards/CardMain';
import CardSmall from 'site/cards/CardSmall';

import ShapkaDesktop from './Desktop';
import ShapkaMobile from './Mobile';
import getGeo from './utils';


function SiteShapka(props) {
  const {
    isMobile,
    isDesktop,
    topTopic,
    news,
    tags,
    mainMenu,
    genresMenu,
    nemuzykaMenuItems,
  } = props;

  const BreakpointShapka = isMobile ? ShapkaMobile : ShapkaDesktop;

  return (
    <BreakpointShapka
      topTopic={topTopic}
      news={news}
      menu={mainMenu}
      {...isDesktop && { tags, genresMenu, nemuzykaMenuItems }}
    />
  );
}

SiteShapka.propTypes = {
  topTopic: modelPropTypes(topicAttributes),
  news: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  tags: PropTypes.arrayOf(modelPropTypes(tagAttributes)),
  isDesktop: PropTypes.bool,
  mainMenu: menuPropTypes(),
  genresMenu: PropTypes.object,
  nemuzykaMenuItems: PropTypes.array,
};

const dataProvider = resolve({
  // Почему-то если отсюда возвращается undefined, то ломается вёрстка
  topTopic({ topTopic, bebopApi, location }) {
    return topTopic || bebopApi
      .getTopics({
        limit: 1,
        list: 'top-announce',
        fields: filterRequiredParams([CardMain], 'fields'),
        include: filterRequiredParams([CardMain], 'include'),
      })
      .then(data => {
        const topic = denormalizeData(data)[0];
        if (topic && topic.attributes && (location.pathname === topic.attributes.link)) {
          return null;
        }
        return topic || null;
      })
      .catch(() => null);
  },

  news({ bebopApi, content, isMobile, location }) {
    if (location.pathname !== '/') return null;

    return content || bebopApi
      .getTopics({
        limit: isMobile ? 5 : 6,
        include: 'tags',
        topic_type: 'news',
        fields: filterRequiredParams([CardSmall], 'fields'),
      })
      .then(data => {
        const result = denormalizeData(data);
        const resultHasData = Array.isArray(result) && result.length > 1;
        return resultHasData ? result : null;
      })
      .catch(() => null);
  },

  tags(props) {
    const {
      bebopApi,
      tags,
      isMobile,
    } = props;

    if (isMobile) return null;
    if (tags) return tags;

    return bebopApi
      .getTags({
        type: 'geo',
        sort: 'title',
      })
      .then(denormalizeData)
      .then(getGeo)
      .catch(() => null);
  },

  mainMenu(props) {
    const {
      bebopApi,
      mainMenu,
    } = props;

    if (mainMenu) return mainMenu;

    return bebopApi
      .getMenu({ menu_slug: 'main' })
      .catch(() => null);
  },

  genresMenu(props) {
    const {
      bebopApi,
      genresMenu,
      isMobile,
    } = props;

    if (isMobile) return null;
    if (genresMenu) return genresMenu;

    return bebopApi
      .getMenu({ menu_slug: 'genres' })
      .catch(() => null);
  },

  nemuzykaMenuItems(props) {
    const {
      bebopApi,
      nemuzykaMenu,
      isMobile,
    } = props;

    if (isMobile) return null;
    if (nemuzykaMenu) return nemuzykaMenu;

    const rubricSlug = 'nemuzyka';
    return bebopApi
      .getRubric({
        rubric_slug: rubricSlug,
      })
      .then(rubric => rubric?.meta?.ancestry?.[0].children
        ?.filter(({ attributes }) => attributes?.enabled)
        ?.map(({ attributes }) => ({
          content: attributes.title,
          link: '/' + rubricSlug + '/' + attributes.slug,
          show: true,
        })
        ))
      .catch(() => null);
  },
});

export default withNonPureBreakpoint(withRouter(dataProvider(SiteShapka)));
