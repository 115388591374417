import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { compose } from 'core/libs/recompose';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import { withBrandingState } from 'core/components/Ad/BrandingContext';

import Link from 'site/components/Link';
import Dropdown from 'core/components/Dropdown';
import ShapkaMenu from 'core/components/ShapkaMenu';
import FullWidth from 'core/components/FullWidth';

import { menuPropTypes } from 'core/utils/prop-types/relationships';

import NewsFeed from 'site/components/NewsFeed';
import PrimePromo from 'site/components/PrimePromo';
// import SberAuth from 'site/components/SberAuth';

import { CardMainM } from 'site/cards/CardMain';

import SearchForm from './SearchForm';
import MenuItems from './MenuItems';

import styles from './index.styl';

export const inputName = 'query';


class ShapkaDesktop extends Component {
  state = {
    isStickyHeaderVisible: false,
    isSearchFocused: false,
    isOpen: false,
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.observerCallback);
    this.observer.observe(this.contentEl);
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isSearchFocused,
    } = this.state;
    const {
      isSearchFocused: wasSearchFocused,
    } = prevState;

    if (isSearchFocused) {
      const formRefToFocus = this.state.isStickyHeaderVisible
        ? 'formStickyRef'
        : 'formRef';
      const formRefToGetValueFrom = this.state.isStickyHeaderVisible
        ? 'formRef'
        : 'formStickyRef';
      this[formRefToFocus][inputName].focus();
      this[formRefToFocus][inputName].value = this[formRefToGetValueFrom][inputName].value;
    }

    if (!isSearchFocused && wasSearchFocused) {
      this.formRef[inputName].blur();
      this.formStickyRef[inputName].blur();
      this.formRef[inputName].value = this.formStickyRef[inputName].value = '';
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
    document.removeEventListener('click', this.handleDocumentClick);
  }

  contentEl = null;
  formRef = null;
  formStickyRef = null;

  observerCallback = ([entry]) => {
    this.setState({ isStickyHeaderVisible: !entry.isIntersecting && entry.boundingClientRect.bottom < document.documentElement.clientHeight });
  };

  handleDocumentClick = ({ target }) => {
    if (!this.formRef.contains(target) && !this.formStickyRef.contains(target)) {
      this.setState({ isSearchFocused: false });
    }
  };

  setIsOpenStub = isOpen => {
    this.setState({ isOpen: isOpen });
  };

  handleSearchSubmit = () => {
    this.setState(({ isSearchFocused }) => ({ isSearchFocused: !isSearchFocused }));
  };

  getHeaderMarkup = ({ refSetter, logo: Logo, logoWidth, logoHeight }) => {
    const {
      theme,
      menu,
      genresMenu,
      nemuzykaMenuItems,
      tags,
    } = this.props;

    const {
      dropdown: {
        link: linkAtoms,
        borderRadius,
      },
    } = theme.controls.shapka;

    const mainMenuItems = menu?.data.attributes.menu_items || [];
    const genres = genresMenu?.data.attributes.menu_items || [];
    const hasStarsSubitems = (genres.length > 0) && (tags && tags.length > 0);
    const hasNemuzykaSubitems = nemuzykaMenuItems?.length > 0;
    const provideRenderers = hasStarsSubitems || hasNemuzykaSubitems;

    const starsRenderer = ({ coreMarkup, index }) => {
      return (
        <Dropdown
          showIcon={false}
          anchor={coreMarkup}
          contentOpacityDuration='0s'
          closeAfterUrlChanged
          onOpen={() => this.setState({ isOpen: true })}
          onClose={() => this.setState({ isOpen: false })}
          contentPosition='center'
          contentPadding='20px'
          contentBorderRadius={borderRadius}
          trigger='hover'
          key={index}
        >
          <style jsx>{`
            .${styles.groupTitle}
              font 700 ${linkAtoms.font}
          `}</style>
          <div className={styles.dropdownContent}>
            {genres.length > 0 && (
              <div className={styles.genres}>
                <div className={styles.groupTitle}>Жанры:</div>
                <MenuItems items={genres} postfix='?type=stars' />
              </div>
            )}
            {tags.length > 0 && (
              <div>
                <div className={styles.groupTitle}>География:</div>
                <MenuItems items={tags} postfix='?type=stars' />
              </div>
            )}
          </div>
        </Dropdown>
      );
    };
    const nemuzykaRenderer = ({ coreMarkup, index }) => {
      return (
        <Dropdown
          showIcon={false}
          anchor={coreMarkup}
          contentOpacityDuration='0s'
          closeAfterUrlChanged
          onOpen={() => this.setState({ isOpen: true })}
          onClose={() => this.setState({ isOpen: false })}
          contentPosition='center'
          contentPadding='20px'
          contentBorderRadius={borderRadius}
          trigger='hover'
          key={index}
        >
          <MenuItems items={nemuzykaMenuItems} />
        </Dropdown>
      );
    };

    return (
      <Fragment>
        <div className={styles.logoWrapper}>
          <Link
            to='/'
            type='blank'
          >
            <Logo
              width={logoWidth}
              height={logoHeight}
            />
          </Link>
        </div>
        <div className={styles.shapkaMenu}>
          <ShapkaMenu
            items={mainMenuItems}
            setIsOpen={this.setIsOpenStub}
            isOpen={this.state.isOpen}
            {...provideRenderers && {
              renderers: {
                '/stars': hasStarsSubitems && starsRenderer,
                '/nemuzyka': hasNemuzykaSubitems && nemuzykaRenderer,
              },
            }}
          />
        </div>
        <SearchForm
          innerRef={refSetter}
          onSubmit={this.handleSearchSubmit}
        />
        <PrimePromo className={styles.sberPromo} />
        {/* <SberAuth className={styles.sberPromo} /> */}
      </Fragment>
    );
  };

  render() {
    const {
      topTopic,
      news,
      theme,
      brandingState: {
        rendered: hasBranding,
      },
    } = this.props;

    const shapkaStyles = theme.controls.shapka;

    const {
      isStickyHeaderVisible,
      isSearchFocused,
    } = this.state;

    const headerMarkupProps = {
      logo: shapkaStyles.logo.Icon,
      logoWidth: shapkaStyles.logo.width,
      logoHeight: shapkaStyles.logo.height,
    };

    return (
      <FullWidth
        className={cx(
          styles.wrapper,
          isSearchFocused && styles._searchFocused,
          hasBranding && '_hasBranding',
        )}
      >
        <style jsx>{`
          .${styles.header}
            padding ${shapkaStyles.padding}
            height ${shapkaStyles.height}px
        `}</style>
        <style jsx>{`
          :global(.${styles.wrapper})
            background no-repeat url('/images/waves/waves.webp') 0 -150px / 100% auto,\
              linear-gradient(#2e337d, #000 370px, #000 calc(100% - 330px), transparent calc(100% - 330px))
          .${styles.stickyHeader}
            background no-repeat url('/images/waves/waves.webp') 0 -125px / 100% auto,\
              linear-gradient(#2E337D, #000000)
            :global(._hasBranding &)
              max-width ${theme.layout.maxWidth}
        `}</style>
        <header
          className={styles.header}
          data-qa='zvuk-header'
        >
          {this.getHeaderMarkup({
            refSetter: el => { this.formRef = el; },
            ...headerMarkupProps,
          })}
        </header>
        <div
          className={styles.content}
          ref={el => {this.contentEl = el;}}
        >
          {!!topTopic && (
            <div className={styles.card}>
              <CardMainM content={topTopic} />
            </div>
          )}
          {!!news && <NewsFeed content={news} />}
        </div>
        <div
          className={cx(styles.stickyHeader, isStickyHeaderVisible && styles._visible)}
        >
          <header className={styles.header} data-qa='zvuk-header-sticky'>
            {this.getHeaderMarkup({
              refSetter: el => { this.formStickyRef = el; },
              ...headerMarkupProps,
            })}
          </header>
        </div>
      </FullWidth>
    );
  }
}

ShapkaDesktop.propTypes = {
  topTopic: PropTypes.object,
  news: PropTypes.array,
  brandingState: PropTypes.object,
  menu: menuPropTypes(),
  theme: PropTypes.object,
  tags: PropTypes.array,
  genresMenu: PropTypes.object,
  nemuzykaMenuItems: PropTypes.array,
};

export default compose(
  skip,
  withTheme,
  withBrandingState,
)(ShapkaDesktop);
